/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Img from 'gatsby-image';
import { Container, ImageWrapper } from './gallery.css';

const GatsbyImage = ({ index, onClick, photo, margin }) => (
  <ImageWrapper
    style={{ margin, height: photo.height, width: photo.width }}
    onClick={e => onClick(e, { index, photo })}
    key={index}
  >
    <Img
      fixed={typeof window === 'undefined' ? { src: {} } : undefined}
      fluid={photo.fluid}
    />
  </ImageWrapper>
);

// const fileNumber = file =>
//   Number(file.node.childImageSharp.fluid.originalName.replace(/[a-z]/gi, ''));

const getImages = imageArray => {
  return (
    [...imageArray]
      // .sort((a, b) => fileNumber(b) - fileNumber(a))
      .map(({ image: { childImageSharp: { fluid, original } } }) => ({
        height: original.height,
        width: original.width,
        src: fluid.originalImg,
        srcSet: fluid.srcSet,
        fluid,
      }))
  );
};

const styleFn = styleObj => ({ ...styleObj, zIndex: 100 });

const ImageGallery = ({ photos, ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const images = getImages(photos);

  const imageClick = (e, obj) => {
    setCurrent(obj.index);
    setOpen(true);
  };

  return (
    <Container>
      {photos.length > 1 && (
        <Gallery
          photos={images}
          onClick={imageClick}
          renderImage={GatsbyImage}
          targetRowHeight={250}
          margin={5}
          {...rest}
        />
      )}

      <ModalGateway>
        {isOpen ? (
          <Modal
            onClose={() => {
              setCurrent(0);
              setOpen(false);
            }}
            styles={{ blanket: styleFn, positioner: styleFn }}
          >
            <Carousel views={images} currentIndex={current} />
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  );
};

export default ImageGallery;
