import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import PageContainer from 'components/pageContainer';
import ImageGallery from 'components/gallery/gallery';
import Footer from 'components/footer';
import Box from 'components/box';
import BackgroundImage from 'gatsby-background-image';
import Intro from 'components/intro';

const GalleryPage = ({ data }) => {
  const backgroundFluidImageStack = [
    data.galleryJson.background.image.childImageSharp.fluid,
    'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%)',
  ].reverse();

  return (
    <Layout>
      <Head pageTitle={data.galleryJson.pageTitle} />
      <Box>
        <BackgroundImage
          Tag="section"
          className="services"
          fluid={backgroundFluidImageStack}
        >
          <Intro header={data.galleryJson.pageTitle} />
        </BackgroundImage>
      </Box>
      <PageContainer>
        <ImageGallery
          columns={width => {
            if (width < 700) {
              return 2;
            } else if (width < 1000) {
              return 3;
            } else {
              return 6;
            }
          }}
          photos={data.galleryJson.content}
        />
      </PageContainer>
      <Footer />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GalleryPage;

export const query = graphql`
  query GallerypageQuery {
    galleryJson {
      pageTitle
      background {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content {
        title
        image {
          childImageSharp {
            original {
              width
              height
            }
            fluid(maxHeight: 300, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
            }
          }
        }
      }
    }
  }
`;
