import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin: 6em 0;
`;

export const ImageWrapper = styled.div`
  /* box-shadow: -1px 3px 6px 1px rgba(0, 0, 0, 0.3); */
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  overflow: hidden;
  cursor: zoom-in;
  div {
    transition: transform 2s;
  }
  :hover {
    box-shadow: -2px 5px 8px 2px rgba(0, 0, 0, 0.3);
    div {
      transform: scale(1.05);
    }
  }
`;
